@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: rgb(37, 37, 37);
  text-decoration: none;
}

a:hover {
  color: darkslategray;
  text-decoration: none;
}

.no-decor { text-decoration: none; }

.bold { font-weight: bold; }

.medium { font-weight: 600; }

.small { font-size: 11px; }

.small-x { font-size: 10px; }

.sp-primary { color: rgb(31, 109, 192); }
.bg-primary-100 { background-color: rgb(178, 214, 241);}
.bg-success-100 { background-color: rgb(222, 238, 223);}
.bg-neutral-100 { background-color: rgb(212, 200, 227);}

.bg-sp-primary { background-color: rgb(43, 171, 240); }

.bg-gray { background-color: rgb(202, 212, 217); }

.bg-lime-200 { background-color: rgb(218, 255, 117); }

.bg-yellow-50 { background-color: rgb(255, 251, 226); }

.bg-blue-50 { background-color: rgb(228, 248, 255); }

.bg-red-50 { background-color: rgb(255, 218, 218); }

.bg-orange-50 { background-color: rgb(255, 242, 224); }

.bg-green-50 { background-color: rgb(230, 255, 235); }

.bg-purple-50 { background-color: rgb(242, 226, 255); }

.bg-pink-50 { background-color: rgb(255, 227, 250); }

.bg-yellow-100 { background-color: rgb(248, 240, 195); }

.bg-blue-100 { background-color: rgb(195, 235, 248); }

.bg-red-100 { background-color: rgb(248, 195, 195); }

.bg-orange-100 { background-color: rgb(248, 225, 195); }

.bg-green-100 { background-color: rgb(195, 248, 206); }

.bg-purple-100 { background-color: rgb(231, 195, 248); }

.bg-pink-100 { background-color: rgb(248, 195, 239); }

.bg-lime-400 { background-color: rgb(187, 255, 0); }

.bg-yellow-400 { background-color: rgb(235, 204, 32); }

.bg-blue-400 { background-color: rgb(41, 202, 255); }

.bg-red-400 { background-color: rgb(255, 58, 58); }

.bg-green-400 { background-color: rgb(29, 221, 67); }

.bg-purple-400 { background-color: rgb(204, 95, 255); }

.bg-pink-400 { background-color: rgb(255, 113, 231); }

.fg-dark { color: rgb(67, 67, 67); }

.fg-yellow-300 { color: rgb(243, 175, 18); }

.fg-blue-300 { color: rgb(25, 172, 245); }

.fg-red-300 { color: rgb(234, 39, 39); }

.fg-green-300 { color: rgb(85, 221, 55); }

.fg-purple-300 { color: rgb(156, 57, 232); }

.fg-pink-300 { color: rgb(219, 76, 226); }

.fg-yellow-500 { color: rgb(191, 134, 0); }

.fg-blue-500 { color: rgb(31, 84, 176); }

.fg-red-500 { color: rgb(189, 4, 4); }

.fg-green-500 { color: rgb(46, 156, 21); }

.fg-purple-500 { color: rgb(98, 16, 160); }

.fg-pink-500 { color: rgb(143, 21, 150); }

.fg-yellow-700 { color: rgb(143, 100, 0); }

.fg-blue-700 { color: rgb(20, 56, 116); }

.fg-red-700 { color: rgb(143, 0, 0); }

.fg-green-700 { color: rgb(30, 102, 13); }

.fg-purple-700 { color: rgb(69, 10, 114); }

.fg-pink-700 { color: rgb(91, 12, 95); }

.card-16 {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0px 0px 15px rgba(86, 86, 86, 0.1);
}

.card-16-outline {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: solid #dadddf;
  background-color: #fafcfd;
  border-width: 1px;
}

.card-8 {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(86, 86, 86, 0.1);
}

.card-8-outline {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: solid #dadddf;
  background-color: #fafcfd;
  border-width: 1px;
}

.card-top-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.login-header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(32,115,138);
  background: linear-gradient(180deg, rgba(32,115,138,1) 0%, rgba(68,138,150,1) 45%, rgba(34,119,137,1) 100%);
  padding: 7px 15px;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin-left: 250px;
  transition: all 0.3s;
}

.asidebar {
  margin-top: 65px;
}

.header {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  right: 0;
  left: 250px;
  background-color: rgb(235, 240, 242);
  padding: 10px 20px;
  transition: all 0.3s;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px #d9d9d9;
  z-index: 995;
}

.footer {
  display: flex;
  top: 100%;
  position: sticky;
  align-items: center;
  background-color: rgb(235, 240, 242);
  padding: 5px 10px;
}

.qr-code {
  width: 70px;
  height: 70px;
  border-radius: 6px;
}

.btn-back {
  background-color: white;
  color: rgb(0, 131, 245);
  border-radius: 6px;
  transition: all 0.3s;
  padding: 5px 14px;
}

.btn-back:hover {
  background-color: rgb(210, 231, 244);
  cursor: pointer;
}

.sidebar {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 250px;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background-color: white;
  padding: 20px 10px 20px;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  transition: all 0.3s;
  z-index: 996;
}

.menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 30px;
  background-color: rgb(249, 211, 127);
  border-radius: 6px;
  transition: all 0.3s;
}

.menu-toggle:hover {
  background-color: rgb(221, 174, 71);
  cursor: pointer;
}

.nav-bar {
  font-size: 11px;
  color: #92d5ee;
  font-weight: 600;
  padding: 0;
  margin: 0;
  list-style: none;
}
.nav-bar li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.nav-bar .nav-item {
  margin-bottom: 5px;
  text-decoration: none;
}
.nav-bar .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  transition: all 0.3s;
  background-color: rgb(212, 242, 255);
  border-radius: 8px;
  padding: 10px 15px;
}
.nav-bar .nav-link .icon-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  font-size: 16px;
  margin-right: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  border-radius: 8px;
}
.nav-bar .nav-link.collapsed {
  color: #2a2a2a;
  background: none;
}

.nav-bar .nav-link:hover {
  color: #1c1c1c;
  background: #ffffff;
  font-weight: 700;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.nav-bar .nav-link:hover .icon-bg {
  background-color: rgb(168, 229, 255);
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.status-active {
  width: 8px;
  height: 8px;
  background-color: springgreen;
}

.logo-sm {
  width: 25px;
  height: 25px;
  background-color: rgb(54, 52, 148);
  padding: 5px;
  border-radius: 6px;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 18px;
  color: white;
  border-radius: 100%;
}

.avatar:hover {
  cursor: pointer;
}

.avatar-sm {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 16px;
  color: white;
  border-radius: 100%;
}

/* Dropdown menus */
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

.dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-item:hover {
  background-color: #f6f9ff;
}

.card-help {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
  padding: 20px;
  background-color: rgb(0, 202, 118);
  border-radius: 16px;
}

.participants {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 270px;
  height: 600px;
  background-color:rgb(246, 245, 245);
  padding: 10px 15px;
}

.chat-area {
  width: 900px;
  height: 540px;
  background-color: white;
  padding: 20px;
  overflow-y: scroll;
}

.chat {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  padding: 5px 10px;
}

.chat-input-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
  padding: 7px 10px;
  border-radius: 100px;
}

.chat-input {
  width: 800px;
}

.news-img {
  width: 275px;
  height: 175px;
  object-fit: cover;
}

.instructions {
  background-color: rgb(41, 41, 41);
  border-radius: 8px;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
}

.offscreen { display: none; }

.valid { color: green; }

.invalid { color: red; }

.hide { display: none; }

.input-field-container {
  display: flex;
  align-items: center;
  position: relative;
}

.input-field {
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  padding-left: 35px; 
}

.input-field-outline {
  padding: 5px 10px;
  background-color: white;
  border-radius: 10px;
  border-color: #aab7cf;
  border-style: solid;
  padding-left: 35px; 
}

input:focus {
  border-color: rgb(171, 234, 0);
  outline: none;
}

.input-field-icon {
  position: absolute;
  left: 10px;
}

.input-outline-primary {
  padding: 5px 10px;
  border: solid 1px rgb(61, 132, 163);
  border-radius: 10px;
  border-style: solid;
  padding-left: 35px;
  transition: all 0.2s;
}

.input-outline-primary:focus {
  border: solid 1px rgb(248, 253, 255);
  outline: none;
}

.img-photo {
  width: 170px;
  height: 200px;
  background-color: rgb(236, 240, 244);
  border-radius: 2px;
  border: solid 1px rgb(116, 149, 174);
}

.upload-btn {
  border-radius: 10px;
  padding: 2px 15px;
  font-family: 'Helvetica', sans-serif;
  transition: .5s;
  border: 1px dashed #BBB;
  text-align: center;
  font-size: 13px;
  background-color: #f8f4f4;
  cursor: pointer;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* MEDIAQUERIES */
@media (max-width: 1199px) {
  .main {
    margin-left: 0;
  }
  .header {
    left: 0;
  }
  .sidebar {
    left: -250px;
  }
}

@media (min-width: 1200px) {
  .main {
    margin-left: 250px;
  }
  .header {
    left: 250px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .main {
    margin-left: 250px;
  }
  .toggle-sidebar .header {
    left: 250px;
  }
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar .main {
    margin-left: 0;
  }
  .toggle-sidebar .header {
    left: 0;
  }
  .toggle-sidebar .sidebar {
    left: -250px;
  }
}